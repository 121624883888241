import { Box, Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Who = () => {
	const { t } = useTranslation()

	return (
		<Paper
			id='about'
			sx={{
				mt: '50px',
			}}
		>
			<Grid container sx={{ p: { md: '80px 90px', xs: '30px 25px' } }}>
				<Typography
					variant='h3'
					mb='25px'
					sx={{
						maxWidth: '750px',
					}}
				>
					{t('section.who.title')}
				</Typography>
				<Box
					sx={{
						borderRadius: '25px 25px 25px 0',
						backgroundColor: '#fff',
						marginBottom: '25px',
						maxWidth: '900px',
						marginLeft: 'auto',
					}}
				>
					<Grid p='25px 23px' container>
						<Box
							sx={{
								background:
									'linear-gradient(90deg, #E9ECEA 1.53%, #FFFFFF 66.3%)',
								width: 'fit-content',
								borderRadius: '25px 25px 25px 0',
							}}
						>
							<Typography variant='h3' p='0 7px'>
								{t('section.who.text1')}
							</Typography>
						</Box>
						<Typography variant='body2'>{t('section.who.text2')}</Typography>
					</Grid>
				</Box>
				<Box
					sx={{
						borderRadius: '25px 25px 25px 0',
						backgroundColor: '#fff',
						marginBottom: '25px',
						maxWidth: '900px',
						marginLeft: 'auto',
					}}
				>
					<Grid p='25px 23px' container>
						<Typography variant='body2'>{t('section.who.text3')}</Typography>
					</Grid>
				</Box>
			</Grid>
		</Paper>
	)
}

export default Who
