import { Grid, Paper, Typography } from '@mui/material'
import PaymentForm from 'components/PaymentForm'
import { useTranslation } from 'react-i18next'

const Checkout = () => {
	const { t } = useTranslation()

	return (
		<Paper sx={{ width: '100%' }}>
			<Grid
				container
				sx={{
					p: {
						md: '30px 25px',
						xs: '15px 10px',
					},
				}}
			>
				<Typography
					variant='h3'
					sx={{
						mb: '20px',
						'@media (min-width: 900px)': {
							fontSize: '42px',
						},
						'@media (min-width: 600px)': {
							fontSize: '30px',
						},
					}}
				>
					{t('section.checkout.title')}
				</Typography>
				<PaymentForm />
			</Grid>
		</Paper>
	)
}

export default Checkout
