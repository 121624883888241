import { Grid } from '@mui/material'

const Burger = ({ showNav }) => (
	<Grid
		container
		sx={{
			width: '16px',
			height: '16px',
			position: 'relative',
			WebkitTransform: 'rotate(0deg)',
			MozTransform: 'rotate(0deg)',
			OTransform: 'rotate(0deg)',
			transform: 'rotate(0deg)',
			WebkitTransition: '.5s ease-in-out',
			MozTransition: '.5s ease-in-out',
			OTransition: '.5s ease-in-out',
			transition: '.5s ease-in-out',
			cursor: 'pointer',
			'&:focus': {
				outline: 'none',
			},
			'& > span': {
				display: 'block',
				position: 'absolute',
				height: '2px',
				width: '16px',
				background: '#000',
				opacity: '1',
				left: '0',
				WebkitTransform: 'rotate(0deg)',
				MozTransform: 'rotate(0deg)',
				OTransform: 'rotate(0deg)',
				transform: 'rotate(0deg)',
				WebkitTransition: '.25s ease-i-out',
				MozTransition: '.25s ease-in-out',
				OTransition: '.25s ease-in-out',
				transition: '.25s ease-in-out',
			},
			'& > span:first-of-type': {
				top: showNav ? '8px' : '4px',
				WebkitTransform: showNav ? 'rotate(135deg)' : 'none',
				MozTransform: showNav ? 'rotate(135deg)' : 'none',
				OTransform: showNav ? 'rotate(135deg)' : 'none',
				transform: showNav ? 'rotate(135deg)' : 'none',
			},
			'& > span:nth-of-type(2)': {
				top: showNav ? '8px' : '10px',
				WebkitTransform: showNav ? 'rotate(-135deg)' : '0',
				MozTransform: showNav ? 'rotate(-135deg)' : '0',
				OTransform: showNav ? 'rotate(-135deg)' : '0',
				transform: showNav ? 'rotate(-135deg)' : '0',
			},
		}}
	>
		<span />
		<span />
	</Grid>
)

export default Burger
