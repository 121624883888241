import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Burger from './components/Burger'
import Nav from './components/Nav'
import TopLine from './components/TopLine'

const Header = () => {
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()
	const [showNav, setShowNav] = useState(false)

	const handleMenu = () => setShowNav(prev => !prev)

	const handleLanguageChange = () => {
		const language = i18n.language.includes('en') ? 'ua' : 'en'
		i18n.changeLanguage(language)
	}

	return (
		<Fragment>
			<TopLine />
			<Grid container maxWidth='1200px' m='auto'>
				<Grid
					container
					height={window.innerWidth < 900 && showNav ? '300px' : '60px'}
					position='absolute'
					zIndex={1}
					maxWidth='1200px'
					sx={{
						p: { md: '20px', xs: '10px' },
						WebkitTransition: 'all .2s ease-in-out',
						MozTransition: 'all .2s ease-in-out',
						msTransition: 'all .2s ease-in-out',
						OTransition: 'all .2s ease-in-out',
						transition: 'all .2s ease-in-out',
					}}
				>
					<Paper
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							borderRadius: '25px',
							width: '100%',
						}}
					>
						<Grid
							container
							p='8px'
							flexWrap='nowrap'
							justifyContent='space-between'
							position='relative'
							sx={{ overflow: 'hidden' }}
						>
							<Grid
								container
								flexDirection='column'
								ml='10px'
								alignItems='center'
								width='fit-content'
								onClick={() => navigate('/')}
								sx={{ cursor: 'pointer' }}
							>
								<Typography variant='subtitle1'>
									{t('header.subtitle')}
								</Typography>
								<Box
									sx={{
										backgroundColor: 'logo.main',
										width: 'fit-content',
										borderRadius: '25px 25px 25px 0',
									}}
								>
									<Typography variant='h5' p='0 7px'>
										{t('header.title')}
									</Typography>
								</Box>
							</Grid>
							<Nav handleMenu={handleMenu} />
							<Grid container width='fit-content' flexWrap='nowrap'>
								<Button
									variant='outlined'
									sx={{ ml: '10px' }}
									onClick={() => navigate('/donate')}
								>
									{t('header.help')}
								</Button>
								<Button
									variant='outlined'
									sx={{
										ml: '10px',
										width: '45px',
										minWidth: 'unset',
										padding: '0',
									}}
									onClick={handleLanguageChange}
								>
									{i18n.language.includes('en') ? 'Ua' : 'En'}
								</Button>
								<Button
									variant='outlined'
									sx={{
										ml: '10px',
										minWidth: '44px',
										display: {
											xs: 'block',
											md: 'none',
										},
									}}
									onClick={handleMenu}
								>
									<Burger showNav={showNav} handleMenu={handleMenu} />
								</Button>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</Fragment>
	)
}

export default Header
