import { Box, ClickAwayListener } from '@mui/material'
import { Splide, SplideSlide } from '@splidejs/react-splide'

const SplideSlider = ({ images, handleClose }) => {
	const options = {
		perMove: 1,
		perPage: 1,
		rewind: true,
		type: 'loop',
		drag: true,
		focus: 'center',
		pagination: false,
		arrows: true,
		breakpoints: {
			640: {
				arrows: false,
			},
		},
	}

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<Box
				sx={{
					position: 'absolute',
					top: '0%',
					left: '50%',
					transform: 'translate(-50%, 0%)',
					width: '100%',
					maxHeight: '100vh',
					height: '100%',
					background: 'rgba(0, 0, 0, 0.5)',
					padding: {
						sm: '20px',
						xs: '10px',
					},
				}}
			>
				<Splide options={options}>
					{images?.map((image, i) => (
						<SplideSlide
							key={i}
							style={{
								textAlign: 'center',
								alignSelf: 'center',
							}}
						>
							<Box
								component='img'
								src={image}
								alt='reporting'
								sx={{
									width: '100%',
									height: 'auto',
									maxHeight: '100vh',
									objectFit: 'contain',
								}}
							/>
						</SplideSlide>
					))}
				</Splide>
			</Box>
		</ClickAwayListener>
	)
}

export default SplideSlider
