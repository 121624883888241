import { Grid, Typography, keyframes } from '@mui/material'
import { useTranslation } from 'react-i18next'

const move = keyframes`
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-100%, 0);
	}
`

const TopLine = () => {
	const { t } = useTranslation()

	return (
		<Grid p='13px 0' container bgcolor='#000'>
			<Grid
				container
				alignItems='center'
				flexWrap='nowrap'
				sx={{
					overflow: 'hidden',
				}}
			>
				{[...Array(30)].map((_, i) => (
					<Typography
						key={i}
						variant='subtitle2'
						color='#fff'
						textTransform='uppercase'
						sx={{
							m: '0 20px 0',
							fontSize: '16px',
							fontWeight: 600,
							animation: `${move} 10s linear infinite`,
							whiteSpace: 'nowrap',
						}}
					>
						{`🇺🇦 ${t('running-line')}`}
					</Typography>
				))}
			</Grid>
		</Grid>
	)
}

export default TopLine
