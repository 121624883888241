import { Button, Grid, Link, Typography } from '@mui/material'
import { FacebookIcon, InstagramIcon } from 'assets/icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<Grid id='contacts' container maxWidth='1200px' m='auto'>
			<Grid
				container
				p='30px 20px'
				sx={{ flexDirection: { md: 'row', xs: 'column' } }}
				flexWrap='nowrap'
			>
				<Grid
					container
					justifyContent='space-between'
					alignItems='center'
					flexWrap='nowrap'
					// mb='35px'
					p='20px'
				>
					<Typography variant='h2'>{t('footer.title')}</Typography>
					<Button variant='outlined' onClick={() => navigate('/donate')}>
						{t('footer.help')}
					</Button>
				</Grid>
				<Grid container flexDirection='column' p='20px'>
					<Grid container mb='10px'>
						<Link
							href='https://www.instagram.com/howareua_charityfoundation/'
							target='_blank'
							rel='noreferrer'
							style={{ marginRight: '15px' }}
						>
							<InstagramIcon />
						</Link>
						<Link
							href='https://www.facebook.com/howareua/'
							target='_blank'
							rel='noreferrer'
						>
							<FacebookIcon />
						</Link>
					</Grid>
					<Typography fontSize='18px' lineHeight='34px'>
						CF.HowAreUA@gmail.com
					</Typography>
					<Typography fontSize='14px' lineHeight='34px' mt='20px'>
						{`2022 (с) ${t('footer.rights')}`}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Footer
