import { Box, Typography } from '@mui/material'

const OptionItem = ({ onClick, active, title, style = {} }) => {
	return (
		<Box
			onClick={onClick}
			sx={{
				backgroundColor: active ? '#fff' : 'transparent',
				width: '50%',
				borderRadius: '18px',
				cursor: 'pointer',
				transition: 'all .2s ease-in-out',
				p: { md: '5px 0', xs: '15px 0' },
				...style,
			}}
		>
			<Typography variant='body2' textAlign='center' lineHeight='20px'>
				{title}
			</Typography>
		</Box>
	)
}

export default OptionItem
