import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ReportTable = ({ data }) => (
	<Grid
		container
		flexWrap='nowrap'
		justifyContent='space-between'
		sx={{
			flexDirection: { md: 'row-reverse', xs: 'column' },
			border: { md: 'none', xs: '1px solid #303030' },
			borderRadius: '25px',
			mb: '20px',
		}}
	>
		<ColumnWrapper>
			{data.map((item, index) =>
				index % 2 !== 0 ? (
					<ItemWrapper
						item={item}
						key={index}
						style={{
							'&:last-child::after': {
								content: "''",
								display: { md: 'none', xs: 'block' },
								width: '100%',
								height: '1px',
								background: '#303030',
								marginTop: '30px',
							},
						}}
					/>
				) : null
			)}
		</ColumnWrapper>
		<ColumnWrapper
			style={{
				p: { md: '20px 25px', xs: '0px 16px 0px' },
			}}
		>
			{data.map((item, index) =>
				index % 2 === 0 ? <ItemWrapper item={item} key={index} /> : null
			)}
		</ColumnWrapper>
	</Grid>
)

export default ReportTable

const ColumnWrapper = ({ children, style }) => (
	<Grid
		container
		flexDirection='column'
		alignItems='center'
		borderRadius='25px'
		sx={{
			p: { md: '20px 25px', xs: '20px 16px 0px' },
			border: {
				md: '1px solid #303030',
				xs: 'none',
			},
			width: { md: '47%', xs: '100%' },
			...style,
		}}
	>
		{children}
	</Grid>
)

const ItemWrapper = ({ item, style }) => {
	const {
		i18n: { language },
	} = useTranslation()

	return (
		<Grid
			container
			p='15px 0'
			sx={{
				'&:not(:last-child)::after': {
					content: "''",
					display: 'block',
					width: '100%',
					height: '1px',
					background: '#303030',
					marginTop: '30px',
				},
				...style,
			}}
		>
			<Grid item xs={8} alignSelf='center'>
				<Typography variant='body2' color='#fff' sx={{ mb: '0' }}>
					{item.name[language]}
				</Typography>
			</Grid>
			<Grid item xs={4} alignSelf='center'>
				<Typography
					variant='h2'
					color='#fff'
					textAlign='end'
					sx={{
						mb: '0',
						fontSize: {
							md: '60px',
							xs: '40px',
						},
						lineHeight: {
							md: '60px',
							xs: '40px',
						},
					}}
				>
					{item.count}
				</Typography>
			</Grid>
		</Grid>
	)
}
