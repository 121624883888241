import { Box, Grid, Typography } from '@mui/material'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import { useTranslation } from 'react-i18next'

const Partners = ({ partners }) => {
	const { t } = useTranslation()

	const options = {
		perMove: 1,
		perPage: 5,
		rewind: true,
		type: 'loop',
		drag: 'free',
		focus: 'center',
		pagination: false,
		arrows: false,
		autoScroll: {
			speed: 1,
		},
		breakpoints: {
			1200: {
				perPage: 3,
			},
			650: {
				perPage: 2,
			},
			450: {
				perPage: 1,
			},
		},
	}

	return (
		<Grid
			container
			flexDirection='column'
			mb='20px'
			sx={{
				'& > div': {
					width: '100%',
				},
			}}
		>
			<Typography
				variant='h4'
				sx={theme => ({
					color: theme.palette.primary.main,
					textAlign: 'center',
					mb: '40px',
				})}
			>
				{t('section.partners.title')}
			</Typography>
			<Splide options={options} extensions={{ AutoScroll }}>
				{partners?.map((partner, i) => (
					<SplideSlide key={i}>
						<Grid
							container
							flexDirection='column'
							alignItems='center'
							justifyContent='space-between'
							flexWrap='nowrap'
							sx={{ height: '100%' }}
						>
							<Grid item>
								<Typography
									variant='h5'
									sx={{
										color: '#FFF',
										cursor: 'pointer',
										maxWidth: '150px',
										mb: '15px',
										textAlign: 'center',
									}}
								>
									{partner.name}
								</Typography>
							</Grid>
							<Grid
								item
								display='flex'
								alignItems='center'
								sx={{ maxHeight: '200px', height: '100%' }}
							>
								<Box
									component='img'
									src={partner.image}
									alt='reporting'
									sx={{
										maxWidth: '200px',
										width: '100%',
										height: 'auto',
										maxHeight: '200px',
									}}
								/>
							</Grid>
						</Grid>
					</SplideSlide>
				))}
			</Splide>
		</Grid>
	)
}

export default Partners
