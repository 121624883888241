import { Box, Grid, Typography } from '@mui/material'
import Photo1 from 'assets/images/photo1.png'
import Photo2 from 'assets/images/photo2.png'
import Photo3 from 'assets/images/photo3.png'
import Photo4 from 'assets/images/photo4.png'
import { useTranslation } from 'react-i18next'

const Team = () => {
	const { t } = useTranslation()

	const data = [
		{
			image: Photo1,
			name: t('section.team.member1.name'),
			subtitle: t('section.team.member1.text'),
			style: {
				mr: { md: 'auto', xs: '0' },
			},
		},
		{
			image: Photo2,
			name: t('section.team.member2.name'),
			subtitle: t('section.team.member2.text'),
			style: {
				position: { md: 'absolute', xs: 'relative' },
				top: { md: '-350px', xs: '0' },
				right: { md: '75px', xs: '0' },
			},
		},
		{
			image: Photo3,
			name: t('section.team.member3.name'),
			subtitle: t('section.team.member3.text'),
			style: {
				position: { md: 'absolute', xs: 'relative' },
				top: { md: '-50px', xs: '0' },
			},
		},
		{
			image: Photo4,
			name: t('section.team.member4.name'),
			subtitle: t('section.team.member4.text'),
			style: {},
		},
	]

	return (
		<Grid id='team' container flexDirection='column'>
			<Typography variant='h3' sx={{ m: '30px 0 20px' }}>
				{t('section.team.title')}
			</Typography>
			<Typography sx={{ mb: '30px', maxWidth: '500px' }}>
				{t('section.team.text1')}
			</Typography>
			<Typography sx={{ maxWidth: '500px' }}>
				{t('section.team.text2')}
			</Typography>
			<Grid
				container
				alignItems='center'
				justifyContent='center'
				position='relative'
			>
				{data.map((item, i) => (
					<Box key={i} sx={{ position: 'relative', mt: '15px', ...item.style }}>
						<Box
							component='img'
							src={item.image}
							alt='photo'
							sx={{
								borderRadius: '50%',
								maxwidth: '342px',
								width: '100%',
							}}
						></Box>
						<Box
							sx={{
								position: 'absolute',
								bottom: '30px',
								left: '50%',
								transform: 'translateX(-50%)',
								maxWidth: '250px',
							}}
						>
							<Typography variant='h4' color='#fff' textAlign='center'>
								{item.name}
							</Typography>
							<Typography
								fontSize='16px !important'
								lineHeight='20px !important'
								color='#fff'
								textAlign='center'
								sx={{ opacity: '0.7' }}
							>
								{item.subtitle}
							</Typography>
						</Box>
					</Box>
				))}
			</Grid>
		</Grid>
	)
}

export default Team
