import { Grid, Typography, Box } from '@mui/material'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Nav = ({ handleMenu }) => {
	const { hash } = useLocation()
	const { t } = useTranslation()

	const navs = [
		{
			title: t('header.nav1'),
			anchor: '/#about',
		},
		{
			title: t('header.nav2'),
			anchor: '/#reporting',
		},
		{
			title: t('header.nav3'),
			anchor: '/#team',
		},
		{
			title: t('header.nav4'),
			anchor: '/#press',
		},
		{
			title: t('header.nav5'),
			anchor: '/#contacts',
		},
	]

	useEffect(() => {
		if (hash) {
			const section = document.getElementById(hash.slice(1))
			section?.scrollIntoView()
		}
	}, [hash])

	return (
		<Grid
			container
			alignItems='center'
			sx={{
				width: { md: 'fit-content', xs: '100%' },
				position: {
					md: 'initial',
					xs: 'absolute',
				},
				top: '70px',
				left: '50%',
				transform: { md: 'none', xs: 'translateX(-50%)' },
				justifyContent: {
					md: 'initial',
					xs: 'space-around',
				},
			}}
		>
			<Grid container>
				{navs.map((nav, index) => (
					<Box
						key={index}
						sx={{
							width: {
								md: 'fit-content',
								xs: '45%',
							},
						}}
						onClick={handleMenu}
					>
						<Link to={nav.anchor} style={{ textDecoration: 'none' }}>
							<Typography
								textAlign='center'
								sx={{
									mb: '0',
									p: {
										lg: '0 22px',
										md: '0 5px',
										xs: '14px',
									},
									fontSize: {
										lg: '20px',
										xs: '14px',
									},
								}}
								p='0 22px'
								fontWeight={600}
							>
								{nav.title}
							</Typography>
						</Link>
					</Box>
				))}
			</Grid>
		</Grid>
	)
}

export default Nav
