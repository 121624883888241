const ExpandArrow = ({
	color = '#ACB958',
	width = '14',
	height = '14',
	style = {},
}) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 14 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={style}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M13.125 6.125C13.6082 6.60825 13.6082 7.39175 13.125 7.875L7.875 13.125C7.39175 13.6082 6.60825 13.6082 6.125 13.125L0.875001 7.875C0.391752 7.39175 0.391752 6.60825 0.875001 6.125C1.35825 5.64175 2.14175 5.64175 2.625 6.125L7 10.5L11.375 6.125C11.8582 5.64175 12.6418 5.64175 13.125 6.125Z'
			fill={color}
		/>
	</svg>
)

export default ExpandArrow
