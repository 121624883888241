import { createTheme } from '@mui/material'

const colors = {
	primary: '#AEBA63',
	secondary: '#000000',
	select: '#D5E27F',
	logo: '#BAB9BE',
	text: '#232716',
}

const theme = createTheme({
	palette: {
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: colors.secondary,
		},
		select: {
			main: colors.select,
		},
		logo: {
			main: colors.logo,
		},
		text: {
			main: colors.text,
		},
	},
	typography: {
		fontFamily: 'DidactGothic',
		body1: {
			color: colors.text,
			fontSize: '18px',
			lineHeight: '28px',

			'@media(min-width: 900px)': {
				fontSize: '22px',
				lineHeight: '34px',
			},
		},
		body2: {
			color: colors.text,
			fontSize: '20px',
			lineHeight: '35px',
			'@media(min-width: 900px)': {
				fontSize: '28px',
				lineHeight: '40px',
			},
		},
		subtitle1: {
			color: colors.text,
			fontSize: '12px',
		},
		subtitle2: {
			color: '#99A99E',
			fontSize: '14px',
		},
		h2: {
			color: colors.text,
			fontWeight: 400,
			fontSize: '42px',
			lineHeight: '45px',

			'@media(min-width: 900px)': {
				fontSize: '96px',
				lineHeight: '105px',
			},
		},
		h3: {
			fontWeight: 400,
			fontSize: '34px',
			lineHeight: '41px',
			'@media(min-width: 900px)': {
				fontSize: '82px',
				lineHeight: '90px',
			},
		},
		h4: {
			fontSize: '26px',
			lineHeight: '40px',
		},
		h5: {
			color: colors.text,
			fontWeight: 600,
			fontSize: '18px',
		},
		h6: {
			fontSize: '16px',
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					WebkitTapHighlightColor: 'transparent',
					backgroundColor: colors.primary,
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '18px 18px 18px 0',
					'& .MuiOutlinedInput-notchedOutline': {
						marginBottom: '10px',
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {},
				text: {
					color: '#000',
					// fontSize: '14px',
					// fontWeight: 600,
					// maxHeight: '44px',
					// height: '100%',
					border: 'none',
					textTransform: 'none',
				},
				outlined: {
					color: '#000',
					backgroundColor: '#fff',
					fontSize: '14px',
					fontWeight: 600,
					maxHeight: '44px',
					height: '100%',
					border: '2px solid #000',
					borderRadius: '25px',
					textTransform: 'none',

					':hover': {
						border: '2px solid #000',
						backgroundColor: '#fff',
					},
				},
				contained: {
					color: '#fff',
					fontSize: '20px',
					fontWeight: 600,
					backgroundColor:
						'linear-gradient(180deg, #CAD874 0%, #A1AE4E 100%, #A1AE4E 100%)',
					textTransform: 'none',
					borderRadius: '18px',
					minHeight: '50px',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				width: 'fit-content',
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					border: 'none',
					color: colors.primary,

					'&:focus-visible': {
						border: 'none',
					},
					'& .MuiOutlinedInput-notchedOutline': {
						border: 'none',
					},
				},
				icon: {
					color: colors.primary,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: colors.select,
					boxShadow:
						'7px 7px 0px rgba(35, 39, 22, 0.15), inset 2px 3px 4px rgba(35, 39, 22, 0.1)',
					background: 'linear-gradient(90deg, #E9ECEA 1.53%, #FFFFFF 66.3%)',
					borderRadius: '25px 25px 25px 0',
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: 'none',
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					background: colors.select,
					borderRadius: '20px !important',
					marginBottom: '15px',

					'&::before': {
						display: 'none',
					},
					'&.Mui-expanded': {
						background: '#F2F8F4',
					},
				},
			},
		},
	},
})

export default theme
