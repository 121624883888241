const FacebookIcon = ({ color = '#232716', width = '40', height = '40' }) => (
	<svg
		width={width}
		height={height}
		viewBox='0 0 40 40'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M25.2137 21.2816L25.8356 17.3301H21.9452V14.7659C21.9452 13.6849 22.4888 12.6311 24.2312 12.6311H26V9.26699C26 9.26699 24.3948 9 22.86 9C19.6559 9 17.5616 10.8924 17.5616 14.3184V17.3301H14V21.2816H17.5616V30.8339C18.2759 30.9431 19.0077 31 19.7534 31C20.4992 31 21.231 30.9431 21.9452 30.8339V21.2816H25.2137Z'
			fill={color}
		/>
		<rect
			x='1'
			y='1'
			width='38'
			height='38'
			rx='19'
			stroke={color}
			strokeWidth='2'
		/>
	</svg>
)

export default FacebookIcon
