import { Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { collection, getDocs, getFirestore } from 'firebase/firestore'
import Splide from 'components/Splide'
import ReportTable from 'components/ReportTable'
import Partners from 'components/Partners'
import { getApp } from 'firebase/app'
import { useTranslation } from 'react-i18next'

const Reporting = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const db = getFirestore(getApp())
	const [showImages, setShowImages] = useState(false)
	const [date, setDate] = useState('')
	const [data, setData] = useState([])
	const [images, setImages] = useState([])
	const [partners, setPartners] = useState([])

	const getReporting = useCallback(async () => {
		const querySnapshot = await getDocs(collection(db, 'reporting'))
		querySnapshot.forEach(doc => {
			const data = doc.data()
			setDate(data.date)
			setData(data.values)
			setImages(data.images)
		})
	}, [db])

	const getPartners = useCallback(async () => {
		const querySnapshot = await getDocs(collection(db, 'partners'))
		let data = []
		querySnapshot.forEach(doc => {
			data.push(doc.data())
		})
		setPartners(data)
	}, [db])

	useEffect(() => {
		getReporting()
		getPartners()
	}, [getReporting, getPartners])

	return (
		<Grid
			id='reporting'
			container
			flexDirection='column'
			mt='75px'
			sx={{ position: 'relative' }}
		>
			{showImages && (
				<Splide images={images} handleClose={() => setShowImages(false)} />
			)}
			<Grid container justifyContent='space-between' alignItems='center'>
				<Typography variant='h3' color='#fff' sx={{ mb: '20px' }}>
					{t('section.reporting.title')}
				</Typography>
				{images?.length > 0 ? (
					<Typography
						variant='h4'
						sx={theme => ({
							color: theme.palette.primary.main,
							cursor: 'pointer',
						})}
						onClick={() => setShowImages(true)}
					>
						{t('section.reporting.photo')}
					</Typography>
				) : null}
			</Grid>
			<Grid container alignItems='center' mb='20px'>
				<Typography color='#fff'>
					{`${t('section.reporting.help-date')} ${date[language]}`}
				</Typography>
			</Grid>
			<ReportTable data={data} />
			<Typography mb='50px' color='#fff' sx={{ opacity: '0.7' }}>
				{t('section.reporting.help-subtitle')}
			</Typography>
			<Partners partners={partners} />
		</Grid>
	)
}

export default Reporting
