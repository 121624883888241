import { Fragment } from 'react'
import { Box, Grid } from '@mui/material'
import Top from './sections/Top'
import Who from './sections/Who'
import Reporting from './sections/Reporting'
import Team from './sections/Team'
import WritesAboutUs from './sections/WritesAboutUs'

const SectionWrapper = ({ children }) => (
	<Grid container maxWidth='1200px' m='auto'>
		<Grid container sx={{ p: { md: '20px', xs: '10px' } }}>
			{children}
		</Grid>
	</Grid>
)

const Home = () => (
	<Fragment>
		<SectionWrapper>
			<Top />
		</SectionWrapper>
		<SectionWrapper>
			<Who />
		</SectionWrapper>
		<Box sx={{ backgroundColor: '#000', width: '100%', mt: '-50px' }}>
			<Grid container maxWidth='1200px' m='auto'>
				<Grid container sx={{ p: { md: '20px', xs: '10px' } }}>
					<Reporting />
				</Grid>
			</Grid>
		</Box>
		<SectionWrapper>
			<Team />
		</SectionWrapper>
		<SectionWrapper>
			<WritesAboutUs />
		</SectionWrapper>
	</Fragment>
)

export default Home
