import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Layout from 'components/Layout'
import Home from 'pages/Home'
import Donate from 'pages/Donate'
import { initializeApp } from 'firebase/app'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

const App = () => {
	initializeApp(firebaseConfig)
	const {
		i18n: { changeLanguage },
	} = useTranslation()

	useEffect(() => {
		const language = localStorage.getItem('i18nextLng')
		if (!['ua', 'en'].includes(language)) {
			if (language.includes('en')) {
				localStorage.setItem('i18nextLng', 'en')
				changeLanguage('en')
			} else {
				localStorage.setItem('i18nextLng', 'ua')
				changeLanguage('ua')
			}
		}
	}, [changeLanguage])

	return (
		<div className='App'>
			<Router>
				<Layout>
					<Routes>
						<Route index element={<Home />} />
						<Route path='donate' element={<Donate />} />
					</Routes>
				</Layout>
			</Router>
		</div>
	)
}

export default App
