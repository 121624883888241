import { Box, Grid, Paper, Typography } from '@mui/material'
import { collection, getDocs, getFirestore } from 'firebase/firestore'
import { getApp } from 'firebase/app'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const WritesAboutUs = () => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const db = getFirestore(getApp())
	const [news, setNews] = useState([])

	const getNews = useCallback(async () => {
		const querySnapshot = await getDocs(collection(db, 'news'))
		let data = []
		querySnapshot.forEach(doc => {
			data.push(doc.data())
		})
		setNews(data)
	}, [db])

	useEffect(() => {
		getNews()
	}, [getNews])

	return (
		<Paper
			id='press'
			sx={{
				mt: '50px',
				width: '100%',
			}}
		>
			<Grid
				container
				p='30px 25px'
				sx={{
					maxWidth: '1160px',
					maxHeight: '1160px',
					height: '100%',
					overflow: 'hidden',
				}}
			>
				<Typography variant='h3' mb='25px'>
					{t('section.about.title')}
				</Typography>
				<Grid
					container
					sx={{
						flexDirection: { md: 'row', xs: 'column' },
						overflowX: { md: 'scroll', xs: 'hidden' },
						height: { md: 'auto', xs: '100%' },
						overflowY: { md: 'hidden', xs: 'scroll' },
						paddingBottom: { md: '0', xs: '30px' },
					}}
					flexWrap='nowrap'
				>
					{news?.map((item, i) => (
						<Box
							key={i}
							sx={{
								borderRadius: '25px 25px 25px 0',
								backgroundColor: '#fff',
								marginBottom: '25px',
								width: { md: '900px', xs: 'auto' },
								ml: { md: i !== 0 ? '50px' : '0', xs: '0' },
								cursor: 'pointer',
							}}
							onClick={() => window.open(item.link, '_blank')}
						>
							<Grid
								p='25px 23px'
								container
								flexWrap='nowrap'
								sx={{ flexDirection: { md: 'row', xs: 'column' } }}
							>
								<Box
									component='img'
									src={item.image}
									alt='news-photo'
									sx={{
										mb: '15px',
										width: { md: 'auto', xs: '100%' },
										maxWidth: '390px',
										height: '220px',
										borderRadius: '20px',
										objectFit: 'cover',
									}}
								/>
								<Grid
									container
									flexDirection='column'
									justifyContent='space-around'
									sx={{
										width: { md: '430px', xs: 'auto' },
										ml: { md: '30px', xs: '0' },
									}}
								>
									<Typography
										variant='h4'
										sx={theme => ({
											color: theme.palette.primary.main,
											textTransform: 'uppercase',
											fontSize: '30px',
											fontWeight: 'bold',
										})}
									>
										{item.name[language]}
									</Typography>
									<Typography variant='body2'>
										{item.description[language]}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					))}
				</Grid>
			</Grid>
		</Paper>
	)
}

export default WritesAboutUs
