import { Grid } from '@mui/material'
import Details from './sections/Details'
import Checkout from './sections/Checkout'
import './index.css'

const Donate = () => {
	return (
		<Grid container maxWidth='1200px' m='auto'>
			<Grid
				container
				mt='100px'
				flexWrap='nowrap'
				justifyContent='space-between'
				alignItems='center'
				sx={{
					p: { md: '20px', xs: '10px' },
					flexDirection: {
						// md: 'row',
						xs: 'column',
					},
				}}
			>
				<Checkout />
				<Details />
			</Grid>
		</Grid>
	)
}

export default Donate
