import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from 'assets/locales/en.json'
import ua from 'assets/locales/ua.json'

const defaultLanguage = 'ua'

const resources = {
	ua: {
		translation: ua,
	},
	en: {
		translation: en,
	},
}

i18n.use(LanguageDetector).use(initReactI18next).init({
	fallbackLng: defaultLanguage,
	resources,
})
