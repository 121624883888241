import { useEffect, useState } from 'react'
import {
	Box,
	FormControl,
	Grid,
	InputAdornment,
	OutlinedInput,
	Typography,
} from '@mui/material'
import OptionsWrapper from './components/OptionsWrapper'
import OptionItem from './components/OptionItem'
import { useTranslation } from 'react-i18next'

const values = [
	{
		title: '₴ 200',
		value: 200,
	},
	{
		title: '₴ 500',
		value: 500,
	},
	{
		title: '₴ 1000',
		value: 1000,
	},
	{
		title: 'інша',
		value: '',
	},
]

const PaymentForm = () => {
	const { i18n } = useTranslation()
	const [paymentValue, setPaymentValue] = useState(values[0])

	useEffect(() => {
		const paymentOptions = {
			options: {
				methods: ['card', 'banklinks_eu', 'wallets', 'local_methods'],
				methods_disabled: [],
				card_icons: ['mastercard', 'visa', 'maestro'],
				active_tab: 'card',
				fields: false,
				title: i18n.language.includes('ua')
					? 'Донат Благодійному фонду "Як ти?"'
					: '',
				full_screen: true,
				button: true,
				email: true,
				subscription: {
					type: 'shown_edit_off',
					periods: ['week', 'month', 'day'],
				},
			},
			params: {
				lang: i18n.language.includes('ua') ? 'uk' : 'en',
				merchant_id: 1516223,
				required_rectoken: 'y',
				currency: 'UAH',
				amount: paymentValue.value * 100,
				recurring_data: {
					every: 1,
					amount: paymentValue.value * 100,
					period: 'month',
					end_time: '2026-11-14',
					start_time: '2019-11-11',
				},
			},
		}

		window.fondy('#checkout-container', paymentOptions)
	}, [i18n.language, paymentValue])

	return (
		<>
			<Grid container flexDirection='column'>
				<Grid sx={{ maxWidth: '410px' }}>
					<Typography variant='subtitle2' fontSize='20px' sx={{ mb: '12px' }}>
						Cума платежу, грн
					</Typography>
					<OptionsWrapper>
						{values.map((val, i) => (
							<OptionItem
								key={i}
								title={val.title}
								onClick={() => setPaymentValue(val)}
								active={paymentValue.title === val.title}
								style={
									i === 0
										? {
												borderBottomLeftRadius: '0',
												width: '25%',
										  }
										: {
												width: '25%',
										  }
								}
							/>
						))}
					</OptionsWrapper>
					<Grid
						container
						sx={{
							mb: '12px',
							WebkitTransition: 'all .2s ease-in-out',
							MozTransition: 'all .2s ease-in-out',
							msTransition: 'all .2s ease-in-out',
							OTransition: 'all .2s ease-in-out',
							transition: 'all .2s ease-in-out',
							height:
								paymentValue.title === values[values.length - 1].title
									? '60px'
									: '0px',
							overflow: 'hidden',
						}}
					>
						<FormControl fullWidth>
							<OutlinedInput
								type='number'
								value={paymentValue.value}
								onChange={e =>
									setPaymentValue(prev => ({ ...prev, value: e.target.value }))
								}
								startAdornment={
									<InputAdornment position='start'>₴</InputAdornment>
								}
								sx={{
									paddingBottom: '5px',
								}}
							/>
						</FormControl>
					</Grid>
					<Typography variant='subtitle2' fontSize='20px'>
						Cпосіб оплати
					</Typography>
				</Grid>
				<Box
					id='checkout-container'
					sx={{
						'& > div#f': {
							backgroundColor: 'transparent !important',
							height: 'fit-content !important',
							// overflow: 'hidden',

							'.f-container': {
								maxHeight: 'fit-content',
							},
						},
					}}
				></Box>
			</Grid>
		</>
	)
}

export default PaymentForm
