import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography,
} from '@mui/material'
import { ExpandArrowIcon } from 'assets/icons'
import { useTranslation } from 'react-i18next'

const req = {
	en: {
		'Company address':
			'80400, UKRAINE, LVIV OBLAST, KAMIANKA-BUZKA DISTRICT, UBYNI VILLAGE, SVITLA STR., H. 14',
		'Name of the bank':
			'JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE',
		'Company Name': 'CF HOW ARE UA',
		USREOU: 44904807,
	},
	ua: {
		'Адреса підприємства':
			"80400 Львiвська Кам'янка-Бузькийс Убинi вул Свтла б.14",
		'Назва банку':
			'АТ КБ «ПРИВАТБАНК», вул. Грушевського, 1д, м. Київ 01001, Україна',
		'Назва підприємства': 'БФ ЯК ТИ- БО',
		ЄДРПОУ: 44904807,
	},
}

const paymentData = [
	{
		currency: 'UAH',
		title: 'Українська гривня',
		payment: {
			IBAN: 'UA533052990000026002021025872',
		},
	},
	{
		currency: 'USD',
		title: 'Американський долар',
		payment: {
			IBAN: 'UA213052990000026004021017747',
		},
	},
	{
		currency: 'EUR',
		title: 'Євро',
		payment: {
			IBAN: 'UA523052990000026000011025549',
		},
	},
	{
		currency: 'PLZ',
		title: 'Польський злотий',
		payment: {
			IBAN: 'UA823052990000026008041022763',
		},
	},
]

const Details = () => {
	const { t } = useTranslation()

	return (
		<Grid container justifyContent='space-between'>
			<Grid
				container
				flexDirection='column'
				alignSelf='start'
				mt='30px'
				sx={{ width: { md: '47%', xs: '100%' } }}
			>
				<Typography
					variant='h3'
					sx={{
						mb: '25px',
						'@media (min-width: 900px)': {
							fontSize: '42px',
						},
					}}
				>
					{t('section.details.title')}
				</Typography>
				{paymentData.map((data, i) => (
					<Accordion key={i}>
						<AccordionSummary
							expandIcon={<ExpandArrowIcon />}
							sx={{ padding: '0 30px' }}
						>
							<Typography fontWeight='bold' sx={{ display: 'contents' }}>
								{data.currency}
								<Typography
									component='span'
									sx={{ ml: '5px' }}
								>{`/ ${data.title}`}</Typography>
							</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ padding: '0 30px 20px' }}>
							<Grid container flexDirection='column'>
								{Object.entries(
									data.currency === 'UAH'
										? { ...data.payment, ...req.ua }
										: { ...data.payment, ...req.en }
								).map(([key, value]) => (
									<Grid container key={key} flexDirection='column' mb='25px'>
										<Typography variant='h6' color='#99A99E'>
											{key}
										</Typography>
										<Typography variant='h5' sx={{ fontWeight: 400 }}>
											{value}
										</Typography>
									</Grid>
								))}
							</Grid>
						</AccordionDetails>
					</Accordion>
				))}
			</Grid>
		</Grid>
	)
}

export default Details
