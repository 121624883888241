import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider, CssBaseline } from '@mui/material'
import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from 'style/theme/theme'
import 'style/font/font.css'
import '@splidejs/react-splide/css'
import './index.css'
import './utils/i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<App />
		</ThemeProvider>
	</React.StrictMode>
)

reportWebVitals()
