import { Grid } from '@mui/material'

const OptionsWrapper = ({ children }) => {
	return (
		<Grid
			container
			sx={{
				border: '1px solid #f4f4f4',
				borderRadius: '18px 18px 18px 0',
				bgcolor: '#D2DCD5',
				overflow: 'hidden',
				mb: '20px',
			}}
		>
			{children}
		</Grid>
	)
}

export default OptionsWrapper
