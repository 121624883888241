import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Top = () => {
	const { t } = useTranslation()

	return (
		<Grid container flexDirection='column' flexWrap='nowrap' mt='100px'>
			<Typography variant='h2' sx={{ maxWidth: '900px' }}>
				{t('section.top.title')}
			</Typography>
			<Typography
				variant='body1'
				sx={{
					mt: {
						md: '30px',
						xs: '20px',
					},
					maxWidth: {
						md: '500px',
						xs: '100%',
					},
					alignSelf: {
						md: 'center',
						xs: 'start',
					},
				}}
			>
				{t('section.top.text1')}
			</Typography>
			<Typography
				variant='body1'
				sx={{
					mt: {
						md: '30px',
						xs: '20px',
					},
					maxWidth: {
						md: '500px',
						xs: '100%',
					},
					alignSelf: {
						md: 'center',
						xs: 'start',
					},
				}}
			>
				{t('section.top.text2')}
			</Typography>
		</Grid>
	)
}

export default Top
